export enum InvoiceTypes {
    GET_INVOICES = '@@invoices/GET_INVOICES',
    GET_INVOICES_SUCCESS = '@@invoices/GET_INVOICES_SUCCESS',
    GET_INVOICES_FAIL = '@@invoices/GET_INVOICES_FAIL',
    GET_INVOICE_DETAIL = '@@invoices/GET_INVOICE_DETAIL',
    GET_INVOICE_DETAIL_SUCCESS = '@@invoices/GET_INVOICE_DETAIL_SUCCESS',
    GET_INVOICE_DETAIL_FAIL = '@@invoices/GET_INVOICE_DETAIL_FAIL',

    ADD_INVOICE_DETAIL = "ADD_INVOICE_DETAIL",
    ADD_INVOICE_DETAIL_SUCCESS = "ADD_INVOICE_DETAIL_SUCCESS",
    ADD_INVOICE_DETAIL_FAIL = "ADD_INVOICE_DETAIL_FAIL",

    DELETE_INVOICE_DETAIL = "DELETE_INVOICE_DETAIL",
    DELETE_INVOICE_DETAIL_SUCCESS = "DELETE_INVOICE_DETAIL_SUCCESS",
    DELETE_INVOICE_DETAIL_FAIL = "DELETE_INVOICE_DETAIL_FAIL",

    UPDATE_INVOICE_DETAIL = "UPDATE_INVOICE_DETAIL",
    UPDATE_INVOICE_DETAIL_SUCCESS = "UPDATE_INVOICE_DETAIL_SUCCESS",
    UPDATE_INVOICE_DETAIL_FAIL = "UPDATE_INVOICE_DETAIL_FAIL",
}
