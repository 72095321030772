export enum Dashboard {
    GET_MARKET_OVERVIEW = "GET_MARKET_OVERVIEW",
    GET_MARKET_OVERVIEW_SUCCESS = "GET_MARKET_OVERVIEW_SUCCESS",
    GET_MARKET_OVERVIEW_FAIL = "GET_MARKET_OVERVIEW_FAIL",

    GET_WALLENT_BALANCE = "GET_WALLENT_BALANCE",
    GET_WALLENT_BALANCE_SUCCESS = "GET_WALLENT_BALANCE_SUCCESS",
    GET_WALLENT_BALANCE_FAIL = "GET_WALLENT_BALANCE_FAIL",

    GET_Invested_Overview = "GET_Invested_Overview",
    GET_Invested_Overview_SUCCESS = "GET_Invested_Overview_SUCCESS",
    GET_Invested_Overview_FAIL = "GET_Invested_Overview__FAIL",
}


