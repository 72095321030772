import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import PropTypes from "prop-types";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-sm.svg";

//Import config
import config from "../../config";
import { createSelector } from "reselect";
import CarouselPage from "./CarouselPage";

interface LoginProps {
  history: object;
}

const Login = (props: any) => {
  const dispatch = useDispatch();

  const errorData = createSelector(

    (state: any) => state.login,
    (state) => ({
      error: state.error,
    })
  );


  useEffect(() => {
    dispatch(loginUser(null, props.router.navigate));
  }, []);  


  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object,
};